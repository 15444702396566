
import React from 'react';
import { LuConstruction } from "react-icons/lu";

const ConstructionPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-200">
      <h1 className="text-4xl font-bold mb-4">Em Construção</h1>
      <p className="text-lg text-gray-700 mb-8">Estamos trabalhando para melhorar sua experiência. Volte em breve!</p>
      <LuConstruction size={50} />
      <p className="text-sm text-gray-500">© {new Date().getFullYear()} Perfil Soluções</p>
    </div>
  );
};

export default ConstructionPage;
