import ConstructionPage from "./pages/ConstructionPage";

function App() {
  return (
    <div>
      <ConstructionPage />
    </div>
  );
}

export default App;
